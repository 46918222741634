<template>
  <r-page-modal :isModal="true">
    <template #page-header>
      <h3 class="page-title">We're sorry to see you go!</h3>
    </template>
    <template #page-content>
      Your monthly subscription is paid until {{ activeUntil }}. If you would
      like to proceed with canceling your subscription, please select "Cancel
      Subcription" below.
      <div class="box" style="background: #fff8e3">
        <b-icon icon="alert" style="color: #ffd500"></b-icon>
        Are you sure? after cancel subscription you will not have the ability to
        use our product anymore, and also cannot access all your files, Not
        ready to loose your data?
        <br />
        Backup all of the data that you need before cancel subcription.
      </div>
      <div class="cf">
        <b-button style="float: right" @click="cancelSubs">
          Cancel Subscription
        </b-button>
        <b-button
          type="is-primary"
          outlined
          style="float: right; margin-right: 10px"
          @click="cancel"
        >
          Cancel
        </b-button>
      </div>
    </template>
  </r-page-modal>
</template>

<script>
export default {
  props: {
    billingID: Number,
    activeUntil: String,
  },
  methods: {
    cancel() {
      this.$emit('close')
    },
    cancelSubs() {
      if (this.billingID) {
        this.$store
          .dispatch('payments/cancelPackage', this.billingID)
          .then(() => {
            this.$emit('close')
            this.$emit('getDataActive')
          })
      }
    },
  },
}
</script>

<style scoped>
.cf::after {
  clear: both;
}
</style>
