<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Package</h2>
    </template>
    <template #page-content>
      <div class="columns">
        <r-card
          class="column is-half is-offset-one-quarter"
          style="padding: 20px; margin-top: 0"
        >
          <div class="columns">
            <div class="column is-6">
              <strong>Active Package</strong>
            </div>
            <div
              class="column is-6"
              v-if="activePackage && activePackage.id != 0"
            >
              <div style="text-align: right; font-size: 13px; height: 22.22px">
                <span style="line-height: 22.22px">
                  Active Until
                  <strong>{{ activeUntil(activePackage.activeDate) }}</strong>
                </span>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12" style="padding-top: 0">
              <b-loading
                :is-full-page="false"
                v-model="isLoadingActive"
                :can-cancel="true"
              ></b-loading>
              <r-card
                style="
                  margin: 0;
                  background: #f8f8f8;
                  padding: 15px;
                  box-shadow: 0px 5px 20px rgb(112 144 176 / 13%);
                "
                v-if="activePackage && activePackage.id != 0"
              >
                <div class="columns">
                  <div
                    class="column is-1"
                    style="padding: 5px; padding-top: 15px"
                  >
                    <div
                      style="
                        background: #fff;
                        border-radius: 50%;
                        width: 37.5px;
                        height: 37.5px;
                        padding: 5px;
                        box-shadow: 0px 5px 20px rgb(112 144 176 / 13%);
                      "
                    >
                      <img src="/images/logo-rise.png" style="width: 100%" />
                    </div>
                  </div>
                  <div class="column is-6">
                    <div>
                      <strong style="text-transform: capitalize">
                        {{ activePackage.name }}
                      </strong>
                    </div>
                    <div>
                      <strong style="color: #bbbbbb">RISE Web App</strong>
                    </div>
                    <div
                      style="
                        margin: 10px 0 20px 0;
                        font-size: 13px;
                        color: #bbbbbb;
                      "
                    >
                      <b-icon icon="account"></b-icon>
                      Up to {{ activePackage.maxUsers }} Users
                    </div>
                    <div>
                      <span
                        style="font-size: 13px; cursor: pointer"
                        @click="cancelPackage"
                      >
                        Cancel Subscription
                      </span>
                    </div>
                  </div>
                  <div class="column is-5" style="padding-right: 5px">
                    <div style="text-align: right">
                      <strong>Rp. {{ activePackage.price | numeral }}</strong>
                      <span style="color: #bbbbbb">&nbsp; / month</span>
                    </div>
                  </div>
                </div>
              </r-card>
              <r-card
                style="
                  margin: 0;
                  background: #f8f8f8;
                  padding: 15px;
                  box-shadow: 0px 5px 20px rgb(112 144 176 / 13%);
                "
                v-else
              >
                <div style="text-align: center">
                  <strong style="font-size: 13px">
                    You have not subscribed to any services. Please start
                    subscribing and enjoy our service packages.
                  </strong>
                </div>
              </r-card>
            </div>
          </div>
        </r-card>
      </div>
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <hr style="margin: 15px" />
          <h4 style="text-align: center; margin: 0; margin-bottom: 15px">
            Our Service Packages
          </h4>
          <div class="columns">
            <b-loading
              :is-full-page="false"
              v-model="isLoadingDataPackage"
              :can-cancel="true"
            ></b-loading>
            <div
              class="column"
              v-for="(data, index) in dataPackage"
              :key="index"
            >
              <r-card style="margin: 0; padding: 10px; font-size: 14px">
                <div class="columns">
                  <div class="column is-6" style="padding-bottom: 0">
                    <div
                      style="
                        background: #fff;
                        border-radius: 50%;
                        width: 37.5px;
                        height: 37.5px;
                        padding: 5px;
                        box-shadow: 0px 5px 20px rgb(112 144 176 / 13%);
                      "
                    >
                      <img src="/images/logo-rise.png" style="width: 100%" />
                    </div>
                  </div>
                  <div class="column is-6" style="padding-bottom: 0">
                    <b-field style="text-align: right">
                      <b-tag
                        rounded
                        :type="
                          data.packageName == 'Company'
                            ? 'info-billing'
                            : data.packageName == 'Medium Business'
                            ? 'is-info'
                            : data.packageName == 'Small Business'
                            ? 'is-success'
                            : ''
                        "
                        style="color: #fff"
                      >
                        {{
                          data.packageName == 'Company'
                            ? 'Discount 20%'
                            : data.packageName == 'Medium Business'
                            ? 'Discount 10%'
                            : data.packageName == 'Small Business'
                            ? 'Most Popular'
                            : ''
                        }}
                      </b-tag>
                    </b-field>
                  </div>
                </div>

                <div style="margin-top: 10px; margin-bottom: 10px">
                  <strong style="text-transform: capitalize">
                    {{ data.packageName }}
                  </strong>
                </div>
                <div>
                  <strong>Rp. {{ data.price | numeral }}</strong>
                  <span style="color: #bbbbbb">&nbsp; / month</span>
                </div>
                <hr />
                <div>
                  <span>What will you get ?</span>
                  <div class="has-text-weight-bold" style="margin-top: 10px">
                    <b-icon icon="check" size="is-small"></b-icon>
                    RISE
                  </div>
                  <div class="has-text-weight-bold">
                    <b-icon icon="check" size="is-small"></b-icon>
                    Max {{ data.maxUsers }} users
                  </div>
                </div>
                <div style="margin-top: 50px">
                  <b-button
                    size="is-medium"
                    expanded
                    @click="pay(data.id, index)"
                  >
                    Buy Now
                  </b-button>
                </div>
              </r-card>
            </div>
            <div class="column" v-if="dataPackage">
              <r-card style="margin: 0; padding: 10px; font-size: 14px">
                <div class="columns">
                  <div class="column is-12" style="padding-bottom: 0">
                    <div
                      style="
                        background: #fff;
                        border-radius: 50%;
                        width: 37.5px;
                        height: 37.5px;
                        padding: 5px;
                        box-shadow: 0px 5px 20px rgb(112 144 176 / 13%);
                      "
                    >
                      <img src="/images/logo-rise.png" style="width: 100%" />
                    </div>
                  </div>
                </div>
                <div style="margin-top: 10px; margin-bottom: 10px">
                  <strong>Enterprise</strong>
                </div>
                <div>
                  <strong>Rp. ~</strong>
                  <span style="color: #bbbbbb">&nbsp; / month</span>
                </div>
                <hr />
                <div>
                  <span>What will you get ?</span>
                  <div class="has-text-weight-bold" style="margin-top: 10px">
                    <b-icon icon="check" size="is-small"></b-icon>
                    RISE
                  </div>
                  <div class="has-text-weight-bold">
                    <b-icon icon="check" size="is-small"></b-icon>
                    Max ~ users
                  </div>
                </div>
                <div style="margin-top: 50px">
                  <b-button
                    size="is-medium"
                    expanded
                    @click="showRequestPackageModal()"
                  >
                    Ask Now
                  </b-button>
                </div>
              </r-card>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirmCancel
        :key="confirmModalKey"
        v-if="isConfirmCancelModal"
        @close="closeModalConfirmModal"
        @getDataActive="fetchData"
        :billingID="activePackage.billingId"
        :activeUntil="activeUntil(activePackage.activeDate)"
      ></ModalConfirmCancel>
      <b-modal v-model="isRequestPackageModal" :width="350" scroll="clip">
        <div class="card">
          <div class="card-content">
            <h3>Request Package</h3>
            <div class="content">
              <section>
                <b-field label="Full Name">
                  <b-input v-model="fullName" maxlength="30"></b-input>
                </b-field>

                <b-field label="Email">
                  <b-input
                    v-model="email"
                    type="email"
                    maxlength="30"
                  ></b-input>
                </b-field>

                <b-field label="Phone Number">
                  <b-input
                    v-model="phoneNumber"
                    placeholder="Enter Phone number"
                  ></b-input>
                </b-field>

                <b-field label="Company Name">
                  <b-input v-model="companyName" maxlength="30"></b-input>
                </b-field>

                <b-field label="Message">
                  <b-input
                    v-model="message"
                    maxlength="200"
                    type="textarea"
                  ></b-input>
                </b-field>
                <b-button width="300px" label="Send" @click="sendRequest()" />
              </section>
            </div>
          </div>
        </div>
      </b-modal>
    </template>
  </r-page>
</template>

<script>
import { mapActions } from 'vuex'
import ModalConfirmCancel from './ModalConfirmCancel.vue'
import moment from 'moment'
export default {
  components: {
    ModalConfirmCancel,
  },
  data() {
    return {
      activePackage: null,
      dataPackage: null,
      isLoadingActive: false,
      isLoadingDataPackage: false,
      confirmModalKey: 0,
      isConfirmCancelModal: false,
      isRequestPackageModal: false,
      billingDatas: [],
      message: null,
      fullName: null,
      phoneNumber: null,
      companyName: null,
      email: null,
    }
  },
  async created() {
    await this.fetchData()
    this.fetchDataBilling()
  },
  methods: {
    ...mapActions({
      getPackageData: 'packageModule/getPackage',
      getPackageActive: 'packageModule/getPackageActive',
      requestCustomPackage: 'packageModule/requestPackage',
      getBillingData: 'packageModule/getBilling',
    }),
    showRequestPackageModal() {
      this.isRequestPackageModal = true
    },
    closeRequestPackageModal() {
      this.isRequestPackageModal = false
    },
    cancelPackage() {
      this.confirmModalKey++
      this.isConfirmCancelModal = true
    },
    closeModalConfirmModal() {
      this.confirmModalKey = 0
      this.isConfirmCancelModal = false
    },
    async fetchDataBilling() {
      let data = await this.getBillingData()
      this.billingDatas = data.data
    },
    async fetchData() {
      this.isLoadingActive = true
      let activePackage = await this.getPackageActive()
      this.activePackage = activePackage
      this.isLoadingActive = false
      this.isLoadingDataPackage = true
      let dataPackage = await this.getPackageData(this.activePackage.id)
      this.dataPackage = dataPackage
      this.isLoadingDataPackage = false
    },
    activeUntil(val) {
      return moment(val).add(30, 'days').format('DD MMMM YYYY')
    },
    pay(id, index) {
      if (this.billingDatas[0] && this.billingDatas[0].status == 'unpaid') {
        this.$buefy.dialog.confirm({
          title: 'Change Unpaid Package',
          message: `Are you sure want to change package plan? 
                    The previous package plan will be canceled`,
          confirmText: 'Yes',
          cancelText: 'No',
          hasIcon: false,
          onConfirm: () => {
            let data = {
              packageId: id,
              companyId: this.$store.state.auth.user.user.companyId,
            }
            let module = ''
            if (this.activePackage.id != 0) {
              module = 'payments/upgradePackage'
            } else {
              module = 'payments/createPackage'
            }
            this.$store.dispatch(module, data).then(
              (success) => {
                this.$router.push({
                  name: 'checkout-billing-package',
                  params: { id: success.id },
                })
              },
              () => {
                this.$swal({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'Something Wrong!',
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'button is-danger',
                  },
                })
              }
            )
          },
        })
      } else if (this.dataPackage[index].price < this.activePackage.price) {
        this.$buefy.dialog.confirm({
          title: 'Downgrade Package',
          message: `Are you sure you want to downgrade your package?<br>
                  You will lose your package benefits such as: Max users: up to ${this.activePackage.maxUsers}
                  users, after downgrade your users will be deactivated for all service, so you need to activate again in <b>User Management</b> menu.
                  Do you still want to continue?`,
          cancelText: 'Cancel',
          confirmText: 'Continue',
          type: 'is-success',
          onConfirm: () => {
            let data = {
              packageId: id,
              companyId: this.$store.state.auth.user.user.companyId,
            }
            let module = ''
            if (this.activePackage.id != 0) {
              module = 'payments/upgradePackage'
            } else {
              module = 'payments/createPackage'
            }
            this.$store.dispatch(module, data).then(
              (success) => {
                this.$router.push({
                  name: 'checkout-billing-package',
                  params: { id: success.id },
                })
              },
              () => {
                this.$swal({
                  icon: 'error',
                  title: 'Failed!',
                  text: 'Something Wrong!',
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'button is-danger',
                  },
                })
              }
            )
          },
        })
      } else {
        let data = {
          packageId: id,
          companyId: this.$store.state.auth.user.user.companyId,
        }
        let module = ''
        if (this.activePackage.id != 0) {
          module = 'payments/upgradePackage'
        } else {
          module = 'payments/createPackage'
        }
        this.$store.dispatch(module, data).then(
          (success) => {
            this.$router.push({
              name: 'checkout-billing-package',
              params: { id: success.id },
            })
          },
          () => {
            this.$swal({
              icon: 'error',
              title: 'Failed!',
              text: 'Something Wrong!',
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'button is-danger',
              },
            })
          }
        )
      }
    },
    sendRequest() {
      let data = {
        fullName: this.fullName,
        email: this.email,
        phoneNumber: this.phoneNumber,
        message: this.message,
        companyName: this.companyName,
      }
      this.requestCustomPackage(data).then(
        () => {
          this.$buefy.toast.open(`Email Sent!`)
          this.closeRequestPackageModal()
        },
        () => {
          this.$buefy.toast.open(`Oops, Something Wrong!`)
          this.closeRequestPackageModal()
        }
      )
    },
  },
  filters: {
    numeral: function (val) {
      return new Intl.NumberFormat('id-ID', {
        minimumFractionDigits: 0,
      }).format(val)
    },
  },
}
</script>
